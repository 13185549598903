

import 'bootstrap';
import Isotope from 'isotope-layout';
import Splide from '@splidejs/splide';
import { Grid } from '@splidejs/splide-extension-grid';
import 'aos';
import AOS from 'aos';
import GLightbox from 'glightbox';
import Swiper from 'swiper';

 function App() {
    "use strict";

    /**
     * Easy isotope news
     */

    var iso = new Isotope( '#isonews', {
        itemSelector: '.itemNews'
    });


     /**
     * Easy selector helper function
     */
    const select = (el, all = false) => {
        el = el.trim()
        if (all) {
            return [...document.querySelectorAll(el)]
        } else {
            return document.querySelector(el)
        }
    }

    /**
     * Easy event listener function
     */
    const on = (type, el, listener, all = false) => {
        if (all) {
            select(el, all).forEach(e => e.addEventListener(type, listener))
        } else {
            select(el, all).addEventListener(type, listener)
        }
    }

    /**
     * Easy on scroll event listener
     */
    const onscroll = (el, listener) => {
        el.addEventListener('scroll', listener)
    }

    /**
     * Navbar links active state on scroll
     */
    let navbarlinks = select('#navbar .scrollto', true)
    const navbarlinksActive = () => {
        let position = window.scrollY + 200
        navbarlinks.forEach(navbarlink => {
            if (!navbarlink.hash) return
            let section = select(navbarlink.hash)
            if (!section) return
            if (position >= section.offsetTop && position <= (section.offsetTop + section.offsetHeight)) {
                navbarlink.classList.add('active')
            } else {
                navbarlink.classList.remove('active')
            }
        })
    }
    window.addEventListener('load', navbarlinksActive)
    onscroll(document, navbarlinksActive)

    /**
     * Scrolls to an element with header offset
     */
    const scrollto = (el) => {
        let header = select('#header')
        let offset = header.offsetHeight

        if (!header.classList.contains('header-scrolled')) {
            offset -= 10
        }

        let elementPos = select(el).offsetTop
        window.scrollTo({
            top: elementPos - offset,
            behavior: 'smooth'
        })
    }

    /**
     * Toggle .header-scrolled class to #header when page is scrolled
     */
    let selectHeader = select('#header')
    if (selectHeader) {
        const headerScrolled = () => {
            if (window.scrollY > 100) {
                selectHeader.classList.add('header-scrolled')
            } else {
                selectHeader.classList.remove('header-scrolled')
            }
        }
        window.addEventListener('load', headerScrolled)
        onscroll(document, headerScrolled)
    }

    /**
     * Back to top button
     */
    let backtotop = select('.back-to-top')
    if (backtotop) {
        const toggleBacktotop = () => {
            if (window.scrollY > 100) {
                backtotop.classList.add('active')
            } else {
                backtotop.classList.remove('active')
            }
        }
        window.addEventListener('load', toggleBacktotop)
        onscroll(document, toggleBacktotop)
    }

    /**
     * Mobile nav toggle
     */
    on('click', '.mobile-nav-toggle', function(e) {
        select('#navbar').classList.toggle('navbar-mobile')
        this.classList.toggle('bi-list')
        this.classList.toggle('bi-x')
    })

    /**
     * Mobile nav dropdowns activate
     */
    //on('click', '.navbar .dropdown > a', function(e) {
    //    if (select('#navbar').classList.contains('navbar-mobile')) {
    //        e.preventDefault()
    //        this.nextElementSibling.classList.toggle('dropdown-active')
    //    }
    //}, true)

    /**
     * Scrool with ofset on links with a class name .scrollto
     */
    on('click', '.scrollto', function(e) {
        if (select(this.hash)) {
            e.preventDefault()

            let navbar = select('#navbar')
            if (navbar.classList.contains('navbar-mobile')) {
                navbar.classList.remove('navbar-mobile')
                let navbarToggle = select('.mobile-nav-toggle')
                navbarToggle.classList.toggle('bi-list')
                navbarToggle.classList.toggle('bi-x')
            }
            scrollto(this.hash)
        }
    }, true)

    /**
     * Scroll with ofset on page load with hash links in the url
     */
    window.addEventListener('load', () => {
        if (window.location.hash) {
            if (select(window.location.hash)) {
                scrollto(window.location.hash)
            }
        }
    });
     /**
      * Testimonials slider
      */
     new Swiper('.testimonials-slider', {
         speed: 600,
         loop: true,
         perPage: 1,
         centeredSlides: true,
         autoplay: {
             delay: 5000,
             disableOnInteraction: false
         },
         slidesPerView: 1,
         breakpoints: {
             320: {
                 slidesPerView: "auto",
                 spaceBetween: 40
             },

             1200: {
                 slidesPerView: "auto",
             }
         }
     });

    /**
     * Clients Slider
     */

    new Splide('.clients-slider', {
        type   : 'loop',
        autoplay: true,
        interval:'2500',
        perPage: 4,
        gap:"6rem",
        pagination: true,
        arrows:false,
        grid: {
            rows: 2,
            cols: 2,
            gap : {
                row: '1rem',
                col: '1.5rem',
            }
        },
        breakpoints: {
            320: {
                perPage: 3,
                gap: 10
            },
            480: {
                perPage: 4,
                gap: 20
            },
            640: {
                perPage: 4,
                gap: 40
            },
            992: {
                perPage: 5,
                gap: 60
            }
        }
    }).mount();
    /*/

    /**
     * Porfolio isotope and filter
     */
    window.addEventListener('load', () => {
        let portfolioContainer = select('.portfolio-container');
        if (portfolioContainer) {
            let portfolioIsotope = new Isotope(portfolioContainer, {
                itemSelector: '.portfolio-item',
                layoutMode: 'fitRows'
            });

            let portfolioFilters = select('#portfolio-flters li', true);

            on('click', '#portfolio-flters li', function(e) {
                e.preventDefault();
                portfolioFilters.forEach(function(el) {
                    el.classList.remove('filter-active');
                });
                this.classList.add('filter-active');

                portfolioIsotope.arrange({
                    filter: this.getAttribute('data-filter')
                });
                aos_init();
            }, true);
        }

    });

    /**
     * Initiate portfolio lightbox
     */
    const portfolioLightbox = GLightbox({
        selector: '.portfokio-lightbox'
    });





    /*/

   /**
    * Animation on scroll
    */
    function aos_init() {
        AOS.init({
            duration: 1000,
            easing: "ease-in-out",
            once: true,
            mirror: false
        });
    }
    window.addEventListener('load', () => {
        aos_init();
    });

    /****price change***/
    let annuals = document.querySelectorAll('.annual');
    let mensuals = document.querySelectorAll('.mensual');
    let checkPer = document.getElementById('flexSwitchCheckDefault');

    const showHidePrice = (period, noperiod) => {
        var butact=document.getElementById('mensuel-but');
        var butnoact = document.getElementById('annual-but');
        if (period == annuals) {
             butact = document.getElementById('annual-but');
             butnoact = document.getElementById('mensuel-but');
        }
        butact.classList.add("actif");
        butnoact.classList.remove("actif");
        period.forEach(e => {e.classList.remove("visually-hidden")});
        noperiod.forEach(e  => {e.classList.add("visually-hidden")});
    }

    document.getElementById("mensuel-but").onclick= function() {
         showHidePrice(mensuals,annuals);
        checkPer.checked = false;
    }
    document.getElementById("annual-but").onclick= function() {
        showHidePrice(annuals,mensuals);
        checkPer.checked = true;
    }
    checkPer.onclick= function() {
         if (checkPer.checked ) {
             showHidePrice(annuals,mensuals)
         }else {
             showHidePrice(mensuals,annuals)
         }
    }


};


export default App;